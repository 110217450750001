import React from 'react';
import Navbar from '../Component/Navbar_GPTPage/Navbar_GPTPage';
import VoiceRecorder from '../Component/VoiceRecorder/VoiceRecorder';
import Footer from '../Component/Footer/Footer';
import '../../src/styles/tailwind.css';
import Sidebar from '../Component/Sidebar/Sidebar';
const ArdraGPTPage = () => {
  return (
    <>
      <Navbar />
      <Sidebar />
      <VoiceRecorder />
      <Footer />
    </>
  );
};
export default ArdraGPTPage;