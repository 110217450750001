import React from "react";
import { FcGoogle } from "react-icons/fc";

const GoogleLoginPage = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  const handleGoogleLogin = () => {
    // Redirect to the backend Google login endpoint
    window.location.href = `${API_URL}/login/google`;
  };

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <button
        onClick={handleGoogleLogin}
        style={{ padding: "10px 20px", fontSize: "18px" }}
        className={`w-full mt-2 py-3 bg-gray-800 border border-gray-600 text-white rounded-lg flex items-center justify-center gap-2 hover:bg-gray-700 transition duration-300 $`}
      >
        <FcGoogle className="text-2xl" />
        Sign in with Google
      </button>
    </div>
  );
};

export default GoogleLoginPage;





