import React, { useState } from 'react';

const faqs = [
  {
    question: 'What is ArdraGPT?',
    answer: 'ArdraGPT is an advanced AI solution designed to help businesses harness the power of artificial intelligence for better decision-making and productivity.'
  },
  {
    question: 'How does ArdraGPT work?',
    answer: 'ArdraGPT utilizes cutting-edge AI technologies to analyze data, generate insights, and provide actionable recommendations to improve business outcomes.'
  },
  {
    question: 'Is ArdraGPT customizable?',
    answer: 'Yes, ArdraGPT offers various customization options to tailor the solution to your specific business needs and requirements.'
  },
  {
    question: 'What kind of support is available?',
    answer: 'We provide comprehensive support including documentation, tutorials, and dedicated customer support to assist with any questions or issues you may encounter.'
  },
];

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div id='faq_section' className="py-12 px-6 lg:px-16">
      <div className="container mx-auto max-w-3xl">
        <h2 className="text-3xl font-bold mb-8 text-center text-gray-900">Frequently Asked Questions</h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div key={index} className="bg-white border rounded-lg shadow-lg overflow-hidden transition-transform duration-300 ease-in-out">
              <button
                className={`w-full px-6 py-4 text-left font-semibold flex justify-between items-center hover:bg-gray-100 focus:outline-none ${activeIndex === index ? 'bg-gray-200' : ''}`}
                onClick={() => handleToggle(index)}
              >
                <span className={`text-gray-900 ${activeIndex === index ? 'text-custom-blue' : 'text-gray-700'}`}>{faq.question}</span>
                <span className={`transform transition-transform ${activeIndex === index ? 'rotate-180' : ''}`}>
                  <svg className="w-6 h-6 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path></svg>
                </span>
              </button>
              {activeIndex === index && (
                <div className="px-6 py-4 bg-gray-50">
                  <p className="text-gray-800">{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
