import React, { useState, useEffect } from "react";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { FaMicrophone, FaCopy, FaEdit } from "react-icons/fa";
import { WiStars } from "react-icons/wi";
import { AiOutlineReload } from "react-icons/ai";
import { IoCloseCircleOutline } from "react-icons/io5";
import { MdCode } from "react-icons/md";
import Lottie from "react-lottie";
import toast, { Toaster } from "react-hot-toast";
import "./VoiceRecorder.css";
import audioWaveAnimation from "../../Assist/audioWaveAnimation.json";
import staticWaveSVG from "../../Assist/staticimage.svg";
import "react-toastify/dist/ReactToastify.css";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import FileUploadPopup from "../FileUploadpopup/FileUploadpopup";
import { useUser } from "../context/UserContext";

const VoiceRecorder = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioUrl, setAudioUrl] = useState("");
  const [queryText, setQueryText] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [graphs, setGraphs] = useState([]);
  const [analysisText, setAnalysisText] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [editableText, setEditableText] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { token, authToken } = useUser();
const effectiveToken = token || authToken;
  const [textAnalysisResult, setTextAnalysisResult] = useState(null); // State to hold the result of text analysis
  const [isAnalyzing, setIsAnalyzing] = useState(false); // State to indicate if analysis is in progress

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSelectFileType = (fileType) => {
    const fileInput = document.getElementById("file-upload");
    if (fileType === "csv") {
      fileInput.accept = ".csv"; // Set accept to CSV
    } else if (fileType === "excel") {
      fileInput.accept = ".xlsx"; // Set accept to Excel
    }
    fileInput.click(); // Trigger file input click
  };
  useEffect(() => {
    if (isRecording) {
      const timer = setTimeout(() => {
        stopRecording();
      }, 15000);

      return () => clearTimeout(timer);
    }
  }, [isRecording]);

  const handleMicClick = () => {
    isRecording ? stopRecording() : startRecording();
  };

  const handleEditText = () => {
    if (editableText) {
      // If there's text to save, save it and hit the API
      setQueryText(editableText); // Save the new text
      analyzeText(editableText); // Immediately hit the text analysis API
      setEditableText(""); // Clear the edit mode
      toast.success("Text saved and analysis triggered! ✅");
    } else {
      // If there's no text yet, enable edit mode
      setEditableText(queryText);
      toast("Edit mode enabled! ✏️");
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);
      recorder.start();
      setIsRecording(true);
      setIsProcessing(true);
      toast("Listening...", { icon: "🎤" });

      recorder.ondataavailable = async (e) => {
        const audioBlob = e.data;
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioUrl(audioUrl);

        const formData = new FormData();
        formData.append("audio", audioBlob, "recording.wav");
        // formData.append("user_id", userId);

        try {
          const response = await axios.post(
            `${API_URL}/voice_to_text`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${effectiveToken}`,
              },
            }
          );

          setQueryText(response.data.text);
          setIsProcessing(false);
          toast.success("Voice successfully converted to text! 😊");
          analyzeText(response.data.text);
        } catch (error) {
          console.error("Error converting voice to text", error);
          setIsProcessing(false);
          toast.error("Failed to convert voice to text.");
        }
      };
    } catch (error) {
      console.error("Error accessing microphone", error);
      setIsProcessing(false);
      toast.error("Failed to access microphone.");
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      setIsRecording(false);
      setIsProcessing(true);
      toast("Processing...", { icon: "⚙️" });
    }
  };

  const analyzeText = async (transcribedText) => {
    setIsAnalyzing(true);
    const API_URL = process.env.REACT_APP_API_URL;

    try {
      toast("Generating text analysis...", { icon: "📝" });

      const response = await axios.post(
        `${API_URL}/generate-text-analysis`,
        {
          data: transcribedText,
          // user_id: userId,
          file_name: fileName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${effectiveToken}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        setTextAnalysisResult(response.data); // Store the analysis result
        setAnalysisText(response.data.text_analysis); // Update the analysis text
        toast.success("Text analysis complete! 🎉");
      } else {
        toast.error(response.data.message || "Text analysis failed.");
      }
      // setAnalysisText(response.data.text_analysis);
      // toast.success("Text analysis complete! 🎉");
    } catch (error) {
      console.error("Error analyzing text:", error);
      toast.error("Error analyzing text. Please try again.");
    } finally {
      setIsAnalyzing(false); // Reset analyzing state
    }
  };

  const generateGraph = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/generate-graph`,
        {
          data: queryText,
          file_name: fileName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${effectiveToken}`,
          },
        }
      );

      setGraphs([
        `${API_URL}/${response.data.graph}`,
        {
          headers: {
            Authorization: `Bearer ${effectiveToken}`,
          },
        },
      ]);
      toast.success("Graph generated! 📊");
    } catch (error) {
      console.error("Error generating graph:", error);
      toast.error("Failed to generate graph.");
    }
  };

  const handleGenerateGraphs = async () => {
    if (!queryText) {
      toast("Please record and convert audio to text first!", { icon: "⚠" });
      return;
    }
    setIsProcessing(true);
    await generateGraph();
    setIsProcessing(false);
  };

  const handleCopyText = () => {
    navigator.clipboard.writeText(analysisText);
    toast.success("Text copied to clipboard! 📋");
  };

  const handleDeletePrompt = () => {
    setQueryText("");
    toast.success("Voice prompt deleted!");
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Check file extension
    const allowedExtensions = ["csv", "xlsx"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      toast.error("Only CSV or Excel files are allowed! 📄");
      return;
    }

    setSelectedFile(file);
    setFileName(file.name);
    toast.success(`File selected: ${file.name} ✅`);

    const formData = new FormData();
    formData.append("file", file);

    // Upload the file
    try {
      await axios.post(`${API_URL}/upload`, formData, {
        headers: {
          Authorization: `Bearer ${effectiveToken}`,
        },
      });
      toast.success("File uploaded successfully!");

      // Prompt the user to provide microphone input
      toast("Now, use your microphone to provide prompts! 🎤", { icon: "👀" });
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Failed to upload file.");
      return;
    }

    // Determine the file type and process accordingly
    const fileType = file.type;

    if (fileType === "text/csv") {
      // Handle CSV file
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        const parsedData = Papa.parse(content, {
          header: true,
          skipEmptyLines: true,
        });
        setCsvData(parsedData.data);
      };
      reader.readAsText(file);
    } else if (
      fileType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      fileType === "application/vnd.ms-excel"
    ) {
      // Handle Excel file
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0]; // Get the first sheet
        const worksheet = workbook.Sheets[firstSheetName]; // Get the actual sheet object
        const parsedData = XLSX.utils.sheet_to_json(worksheet); // Convert the sheet to JSON
        console.log(parsedData);
        setCsvData(parsedData);

        // const worksheet = workbook.Sheets[firstSheetName];
        // const parsedData = XLSX.utils.sheet_to_json(firstSheetName);
        // setCsvData(parsedData);
        // Convert the sheet to JSON
        // const jsonData = XLSX.utils.sheet_to_json(worksheet);
        // console.log(jsonData);
        // setCsvData(jsonData);
      };
      reader.readAsArrayBuffer(file);
    } else {
      alert("Please upload a valid CSV or Excel file.");
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: isRecording,
    animationData: audioWaveAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const handleChange = (e) => {
    setQueryText(e.target.value);
  };

  return (
    <div className="voice-recorder">
      <Toaster position="top-right" reverseOrder={false} />

      <div className="file-upload-container">
        <label className="upload-button" onClick={openModal}>
          Upload your data-file here 📄
        </label>
        <input
          type="file"
          onChange={handleFileUpload}
          className="file-input"
          id="file-upload"
          style={{ display: "none" }}
        />

        <FileUploadPopup
          isOpen={isModalOpen}
          onClose={closeModal}
          onSelect={handleSelectFileType}
        />
        {/* Display file name and table preview for CSV files */}
        {fileName && (
          <div
            style={{
              textAlign: "center",
              margin: "0 auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p style={{ marginBottom: "10px" }}>
              <strong>Selected file:</strong> {fileName}
            </p>
            {csvData.length > 0 && (
              <table
                style={{
                  width: "110%",
                  borderCollapse: "collapse",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <thead>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    {Object.keys(csvData[0]).map((key, index) => (
                      <th
                        key={index}
                        style={{
                          padding: "8px",
                          textAlign: "center",
                          borderBottom: "1px solid #ddd",
                          fontSize: "13px",
                          color: "#333",
                        }}
                      >
                        {key}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {csvData.slice(0, 5).map((row, rowIndex) => (
                    <tr
                      key={rowIndex}
                      style={{
                        backgroundColor:
                          rowIndex % 2 === 0 ? "#fff" : "#f9f9f9",
                      }}
                    >
                      {Object.values(row).map((value, colIndex) => (
                        <td
                          key={colIndex}
                          style={{
                            padding: "6px",
                            borderBottom: "1px solid #ddd",
                            fontSize: "11px",
                            color: "#555",
                          }}
                        >
                          {value}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>

      <div className="audio-wave">
        {isRecording ? (
          <Lottie options={defaultOptions} height={200} width={200} />
        ) : (
          <img
            src={staticWaveSVG}
            alt="Static wave"
            style={{ height: "200px", width: "200px" }}
          />
        )}
      </div>

      <div className="audio-container">
        <button
          onClick={handleMicClick}
          className={`mic-button ${isRecording ? "recording" : ""}`}
        >
          <FaMicrophone size={20} />
        </button>

        <audio controls className="audio-player" src={audioUrl}>
          Your browser does not support the audio element.
        </audio>
      </div>

      <div className="query-display">
        <div className="query-header">
          <h3>Voice Prompt</h3>
          <div className="icon-container">
            <button onClick={handleEditText} className="edit-button">
              {editableText ? "✅" : <FaEdit size={20} />}
            </button>

            <IoCloseCircleOutline
              size={20}
              className="close-icon edit-button "
              onClick={handleDeletePrompt}
            />
            {/* <AiOutlineReload className="refresh-icon" onClick={handleDeletePrompt} /> */}

            {/* <button className="delete-button edit-button " onClick={handleDeletePrompt}>
                <FaTrashAlt />
              </button> */}
          </div>
        </div>
        {editableText ? (
          <input
            type="text"
            id="edit-text"
            value={editableText}
            onChange={(e) => setEditableText(e.target.value)}
            onBlur={() => {
              setQueryText(editableText);
              setEditableText("");
            }}
          />
        ) : (
          <span onClick={() => setEditableText(queryText)}>{queryText}</span>
        )}

        <div className="voice-text">
          {/* <p>{queryText}</p>
          {isProcessing && <p className="status-text">Processing...</p>} 
          {!isProcessing && <p>{queryText}</p>} */}

          {/* {queryText && <p>{queryText}</p>} */}
          {!queryText && isProcessing && (
            <p className="status-text">Processing...</p>
          )}
          {/* r */}
        </div>
      </div>

      <div className="analysis-display">
        <div className="analysis-header">
          <h3>Text Analysis</h3>
          <button onClick={handleCopyText} className="copy-button">
            <FaCopy size={20} />
          </button>
        </div>
        <p>{analysisText}</p>
      </div>

      <button
        onClick={handleGenerateGraphs}
        className="generate-button"
        disabled={isProcessing}
      >
        {isProcessing ? (
          <ClipLoader size={20} color={"#0A1F44"} />
        ) : (
          <>
            Generate Graph
            <WiStars style={{ color: "#0A1F44", fontSize: "28px" }} />
          </>
        )}
      </button>

      <div className="graph-display">
        {graphs.map((graph, index) => (
          <div key={index} className="graph-container">
            <img src={graph} alt={`Graph ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default VoiceRecorder;
