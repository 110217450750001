import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import "../src/styles/tailwind.css";
import LandingPage from "./Pages/LandingPage";
import ArdraGPTPage from "./Pages/ardraGPTPage";
import Profile from "./Component/Profile/Profile";
import PromptHistoryTable from "./Component/PromptHistoryTable/PromptHistoryTable";
import { UserProvider} from "./Component/context/UserContext";

function App() {

  return (
    <UserProvider>
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/ardraGPTPage" element={<ArdraGPTPage />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/prompt' element={<PromptHistoryTable />} />
      </Routes>
    </Router>
    </UserProvider>
    );
}

export default () => (
  <UserProvider>
    <App />
  </UserProvider>
);

// const Layout = () => {
//   const navigate = useNavigate();

  
//   const handleSettingsClick = () => {
//     navigate('/settings'); 
//   };

//   return (
//     <div className="flex h-screen">
    
//       <Sidebar1 onSettingsClick={handleSettingsClick} />

      
//       <div className="flex-1 overflow-y-scroll p-6 bg-gray-100">
//         <Routes>
//           <Route path="/" element={<VoiceRecorder />} />
//           <Route path="/settings/*" element={<SettingsLayout />} />
//         </Routes>
//       </div>
//     </div>
//   );
// };


   
 

    // <LandingPage/>




    //base 
  // <>
  //     <Navbar/>
  //     <VoiceRecorder/>
  //     <Footer/>
  //   </>
  
    //sidebar & base
    // <Router>
    //   <div className="App">
    //     <Routes>
    //       <Route path="/*" element={<Layout />} />
    //     </Routes>
    //   </div>
    // </Router>
 
