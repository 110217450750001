import React, { useState } from "react";
import {
  FaUser,
  FaList,
  FaNetworkWired,
  FaCog,
  FaSignOutAlt,
  FaChevronLeft,
  FaChevronRight,
  FaDollarSign,
  FaKey,
  FaWrench,
  FaChevronUp,
  FaChevronDown,
} from "react-icons/fa";
import "./Sidebar.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useUser } from "../context/UserContext";
import Cookies from 'js-cookie'; 

const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isTopbarOpen, setIsTopbarOpen] = useState(false);
  const navigate = useNavigate();
  // const user = useUser();
  // const loginStreak = user?.loginStreak || 0;
  // const creditsRemaining = user?.creditsRemaining || 0;
  const { loginStreak, creditsRemaining } = useUser();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleTopbar = () => {
    setIsTopbarOpen(!isTopbarOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleLogout = () => {
    try {
      // Clear user data from local storage
      localStorage.removeItem("userId");
      localStorage.removeItem("token");
      Cookies.remove('auth_token'); 

      navigate("/");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <>
      {/* Sidebar for Larger Screens */}
      <aside className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}>
        <div className="sidebar-toggle" onClick={toggleSidebar}>
          {isSidebarOpen ? (
            <FaChevronLeft className="sidebar-toggle-icon" />
          ) : (
            <FaChevronRight className="sidebar-toggle-icon" />
          )}
        </div>

        <div className="sidebar-content">
          <div className="sidebar-items">
            {[
              { text: "AI", label: "Home", path: "/ardraGPTPage" },
              { icon: FaUser, label: "Profile", path: "/profile" },
              { icon: FaList, label: "My Prompts", path: "/prompt" },
            ].map((item, index) => (
              <div
                className="sidebar-item"
                key={index}
                onClick={() => handleNavigation(item.path)}
              >
                {item.text ? (
                  <span className="text-icon">{item.text}</span>
                ) : (
                  <item.icon className="icon" />
                )}
                {isSidebarOpen && <span>{item.label}</span>}
              </div>
            ))}
          </div>
          <div
            className="sidebar-item"
            onClick={handleNavigation.bind(null, "/buy-token")}
          >
            <FaDollarSign className="icon" />
            {isSidebarOpen && <span>Buy Token</span>}
          </div>
          <div
            className={`sidebar-sub-item ${isSidebarOpen ? "row" : "column"}`}
          >
             <span className="icon">🔥</span>
            {isSidebarOpen && (
              <span className="token-count">{loginStreak} days</span>
            )}
            <span className="icon">🪙</span>
            {isSidebarOpen && (
              <span className="token-count">{creditsRemaining}</span>
            )}
          </div>

          <div className="sidebar-bottom">
            <div className="sidebar-item-bottom" onClick={handleLogout}>
              <FaSignOutAlt className="icon" />
              {isSidebarOpen && <span>Logout</span>}
            </div>
          </div>
        </div>
      </aside>

      {/* Topbar for Small Devices */}
      <nav className={`topbar ${isTopbarOpen ? "open" : "closed"}`}>
        <div className="topbar-toggle" onClick={toggleTopbar}>
          {isTopbarOpen ? (
            <FaChevronUp className="topbar-toggle-icon" />
          ) : (
            <FaChevronDown className="topbar-toggle-icon" />
          )}
        </div>
        {isTopbarOpen && (
          <div className="topbar-content">
            <div className="topbar-sub-item">
              <span className="icon">🔥</span>
              {isSidebarOpen && (
                <span className="token-count">{loginStreak} days</span>
              )}
              <span className="icon">🪙</span>
              {isSidebarOpen && (
                <span className="token-count">{creditsRemaining}</span>
              )}
            </div>

            {/* Navigation items */}
            {[
              { text: "AI", label: "Home", path: "/ardraGPTPage" },
              { icon: FaUser, label: "Profile", path: "/profile" },
              { icon: FaList, label: "My Prompts", path: "/prompt" },
              { icon: FaDollarSign, label: "Buy Token", path: "/buy-token" },
              { icon: FaSignOutAlt, label: "Logout", path: "/" },
            ].map((item, index) => (
              <div
                className="topbar-item"
                key={index}
                onClick={
                  item.label === "Logout"
                    ? handleLogout
                    : () => handleNavigation(item.path)
                }
              >
                {item.text ? (
                  <span className="topbar-text-icon">{item.text}</span>
                ) : (
                  <item.icon className="icon" />
                )}
                <span>{item.label}</span>
              </div>
            ))}
          </div>
        )}
      </nav>
    </>
  );
};

export default Sidebar;
