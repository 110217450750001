import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaEdit, FaEnvelope, FaPhone, FaMapMarkerAlt } from "react-icons/fa";
import "./Profile.css";
import profileImage from "../../Assist/avtar2.png";
import Navbar from "../Navbar_GPTPage/Navbar_GPTPage";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import { useUser } from "../context/UserContext";

const Profile = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const { token, authToken } = useUser();
const effectiveToken = token || authToken;
  const [isEditing, setIsEditing] = useState(false);
  const [profileData, setProfileData] = useState({
    full_name: "",
    email: "",
    phone: "",
    location: "",
    bio: "",
    login_streak: 0,
    credits_remaining: 0,
    profile_image_url: profileImage,
  });
  const [updatedData, setUpdatedData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/profile`, {
          headers: {
            Authorization: `Bearer ${effectiveToken}`,
          },
        });
        const data = response.data;
        setProfileData({
          full_name: `${data.first_name} ${data.last_name}`,
          email: data.email || "",
          phone: data.phone || "",
          location: data.profile?.location || "",
          bio: data.profile?.bio || "",
          profile_image_url: data.profile?.profile_image_url || profileImage,
          login_streak: data.profile?.login_streak || 0,
          credits_remaining: data.profile?.credits_remaining || 0,
        });
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    fetchProfile();
  }, [API_URL, effectiveToken]);

  const handleEditToggle = () => {
    setIsEditing((prev) => !prev);
    if (!isEditing) {
      setUpdatedData({
        phone: profileData.phone,
        location: profileData.location,
        bio: profileData.bio,
      });
    } else {
      setUpdatedData({});
    }
  };

  const handleInputChange = (e) => {
    setUpdatedData({ ...updatedData, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    try {
      await axios.put(`${API_URL}/api/profile`, updatedData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${effectiveToken}`,
        },
      });
      setProfileData((prev) => ({ ...prev, ...updatedData }));
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating profile data:", error);
    }
  };
  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
      console.log("File selected:", e.target.files[0]); // Log the selected file
    }
  };

  const handleUploadPicture = async () => {
    console.log("Selected file:", selectedFile); // Log the selected file

    if (!selectedFile) {
      console.error("No file selected");
      return; // Exit early if no file is selected
    }

    const formData = new FormData();
    formData.append("profile_picture", selectedFile);

    try {
      const response = await axios.post(
        `${API_URL}/upload_profile_picture`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${effectiveToken}`,
          },
        }
      );
      console.log("Profile picture uploaded successfully", response.data);

      setProfileData((prev) => ({
        ...prev,
        profile_image_url:
          response.data.profile_image_url || prev.profile_image_url,
      }));
      console.log("Profile picture URL:", response.data.profile_image_url);
      setSelectedFile(null);
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    }
  };

  return (
    <div className="profile-page">
      <Navbar />
      <Sidebar
        loginStreak={profileData.login_streak}
        creditsRemaining={profileData.credits_remaining}
      />
      <div className="left-section">
        <div className="profile-header">
          <div className="image-upload-container">
            <img
              className="profile-image"
              src={profileData.profile_image_url}
              alt={profileData.full_name}
            />
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="file-input"
              style={{ display: "none" }}
              id="file-input"
            />
            <label htmlFor="file-input" className="image-upload-button">
              <FaEdit />
            </label>
            {selectedFile && (
              <div className="file-buttons">
                <button
                  onClick={handleUploadPicture}
                  className="profile-save-button"
                >
                  Save
                </button>
                <button
                  onClick={() => setSelectedFile(null)}
                  className="profile-edit-button"
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
          <h2 style={{ marginTop: selectedFile ? "50px" : "10px" }}>
            {profileData.full_name}
          </h2>

          <h3>
            <FaMapMarkerAlt />{" "}
            {isEditing ? (
              <input
                type="text"
                name="location"
                value={updatedData.location || ""}
                onChange={handleInputChange}
                placeholder="Location"
                className="profile-input"
              />
            ) : (
              profileData.location
            )}
          </h3>
          <div className="details">
            <p>
              <FaEnvelope /> <strong>Email:</strong> {profileData.email}
            </p>
            <p>
              <FaPhone /> <strong>Phone:</strong>{" "}
              {isEditing ? (
                <input
                  type="text"
                  name="phone"
                  value={updatedData.phone || ""}
                  onChange={handleInputChange}
                  placeholder="Phone"
                  className="profile-input"
                />
              ) : (
                profileData.phone
              )}
            </p>
          </div>
          <div className="edit-buttons-container">
            {isEditing ? (
              <>
                <button
                  onClick={handleEditToggle}
                  className="profile-edit-button"
                >
                  Cancel
                </button>
                <button onClick={handleSave} className="profile-save-button">
                  Save
                </button>
              </>
            ) : (
              <button
                onClick={handleEditToggle}
                className="profile-edit-button"
              >
                <FaEdit /> Edit Profile
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="right-section">
        <div className="bio">
          <h3>Bio</h3>
          {isEditing ? (
            <textarea
              name="bio"
              value={updatedData.bio || ""}
              onChange={handleInputChange}
              className="profile-textarea"
            />
          ) : (
            <p>{profileData.bio}</p>
          )}
        </div>
        <div className="login-info">
          <p>
            <strong>Login Streak:</strong> {profileData.login_streak} days
          </p>
          <p>
            <strong>Credits Remaining:</strong> {profileData.credits_remaining}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
