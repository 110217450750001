import React, { useState,useEffect} from 'react';
import './Navbar_landingPage.css'; 
import logo from '../../Assist/navbarLogo.png';

const Navbar = ({ isVisible }) => {
  const [navbarTransparent, setNavbarTransparent] = useState(false);
  const handleScroll = () => {
    const scrollY = window.scrollY;
    setNavbarTransparent(scrollY > 50); // Adjust this value for when to change transparency
  };
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`navbar1 ${navbarTransparent ? 'transparent' : ''} ${isVisible ? 'visible' : 'hidden'}`}>
      <div className="brand">
      <img src={logo} alt="Ardra AI Logo" className="navbar-logo-landingPage" />
      <p>ardraGPT</p>
      </div>
       
      <ul className="nav-links1">
        <li><a href="#features-section">Features</a></li>
        <li><a href="#pricing">Pricing</a></li>
        <li><a href="#faq_section">FAQ</a></li>
        <li className="get-started"><a href="#signinsignup_section">Get started</a></li>
      </ul>
    </nav>
  );
};

export default Navbar;
