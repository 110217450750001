import React, { useEffect, useState } from 'react';
import './PromptHistoryTable.css'; 
import { useUser } from '../context/UserContext'; 
import Navbar from '../Navbar_GPTPage/Navbar_GPTPage';
import Sidebar from '../Sidebar/Sidebar';
import Pagination from '../Pagination/Pagination';

const PromptHistoryTable = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const { token, authToken } = useUser();
const effectiveToken = token || authToken;
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); 

  const itemsPerPage = 10; // Display 10 items on every page

  // Fetching the data from the API
  useEffect(() => {
    const fetchData = async () => {
      // if (!userId) return; 

      try {
        const response = await fetch(`${API_URL}/prompt_datasets`,{
          headers: {
            Authorization: `Bearer ${effectiveToken}`,
          },});

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json(); 
        setData(result.prompts_and_datasets || []); 
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message); 
      } 
    };

    fetchData();
  }, [API_URL]); // Re-run effect when API_URL changes


  if (error) {
    return <div>Error: {error}</div>; 
  }

  // Pagination logic
  const totalItems = data.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const startIdx = (currentPage - 1) * itemsPerPage;
  const endIdx = startIdx + itemsPerPage;

  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="table-container">
        <h2 className="table-title">Prompt Dataset Table</h2>
        {data.length > 0 ? (
          <table className="responsive-table">
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>Prompt</th>
                <th>File Name</th>
              </tr>
            </thead>
            <tbody>
              {data.slice(startIdx, endIdx).map((item, index) => (
                <tr key={index}>
                  <td>{startIdx + index + 1}</td>
                  <td>{item.prompt_text}</td> 
                  <td>{item.dataset_url}</td>  
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className='Empty-table'>No data available</p> // Message when no data is available
        )}
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default PromptHistoryTable;
