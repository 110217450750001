import React, { useState, useEffect, useRef } from 'react';
import Navbar from '../Component/Navbar_landingPage/Navbar_landingPage';
import SigninSignup from '../Component/SigninSignup/SigninSignup';
import FeatureSection from '../Component/Features';
import Pricing from '../Component/Pricing/Pricing';
import Footer from '../Component/Footer/Footer';
import FAQ from '../Component/Faq';

function LandingPage() {
  const [showNavbar, setShowNavbar] = useState(false);
  const heroRef = useRef(null);

  const handleScroll = () => {
    if (heroRef.current) {
      const heroHeight = heroRef.current.getBoundingClientRect().height;
      if (window.scrollY > heroHeight) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <Navbar isVisible={showNavbar} />
      <div ref={heroRef} className="h-screen bg-gray-900">
        <SigninSignup />
      </div>
      <FeatureSection />
      <Pricing />
      <FAQ/>
      <Footer />
    </div>
  );
}

export default LandingPage;
