import React, { useState } from 'react';
import ModalForm from '../GenerativeAIExpertPopup/GenerativeAIExpertPopup';
import './Pricing.css';

const Pricing = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <section id="pricing" className="pricing-section">
      <div className="pricing-container">
        <div className="pricing-card">
          <h2 className="pricing-title">Pricing</h2>
          <p className="pricing-text">
            "To learn more about our pricing options, feel free to contact us!"
          </p>
          <button className="pricing-button" onClick={openModal}>
            Talk to a Generative AI Expert
          </button>
          <ModalForm isOpen={isModalOpen} onClose={closeModal} />
        </div>
      </div>
    </section>
  );
};

export default Pricing;
