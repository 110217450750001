import React from 'react';
import './FileUploadpopup.css';
const FileUploadPopup = ({ isOpen, onClose, onSelect }) => {
  if (!isOpen) return null;
  const handleFileSelection = (fileType) => {
    onSelect(fileType);
    onClose();
  };
  return (
    <div className="modal-overlay1">
      <div className="modal-content1">
      <button className="close-button" onClick={onClose} aria-label="Close">
          ✖
        </button>
        <h2>Select Your Data File</h2>
        <button onClick={() => handleFileSelection('csv')}>
          <span role="img" aria-label="csv">📄</span> CSV File
        </button>
        <button onClick={() => handleFileSelection('excel')}>
          <span role="img" aria-label="excel">📊</span> Excel File
        </button>
        <button disabled>
          <span role="img" aria-label="oracle">🗄️</span> Oracle File
        </button>
        <button disabled>
          <span role="img" aria-label="other">📦</span> Other Third-Party Apps
        </button>
      </div>
    </div>
  );
};
export default  FileUploadPopup;
