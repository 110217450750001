import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [userId, setUserId] = useState(localStorage.getItem('userId') || ''); 
  const [token, setToken] = useState(localStorage.getItem('token') || ''); 
  const [authToken, setAuthToken] = useState(Cookies.get('auth_token') || ''); 
  const [loginStreak, setLoginStreak] = useState(0);
  const [creditsRemaining, setCreditsRemaining] = useState(0);

  const fetchUserData = async () => {
    const effectiveToken = token || authToken; // Use the token from state or the authToken from cookies
  
    if (!effectiveToken) {
      console.log("No effective token found. Skipping user data fetch.");
      return;
    }
  
    try {
      console.log("Fetching user data with effective token:", effectiveToken); // This should log for both tokens
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/profile`, {
        headers: { Authorization: `Bearer ${effectiveToken}` },
      });
      const data = response.data;
  
      console.log("User profile data fetched:", data); // Log the fetched data
  
      if (data.profile) {
        setLoginStreak(data.profile.login_streak || 0);
        setCreditsRemaining(data.profile.credits_remaining || 0);
        console.log("Login streak set to:", data.profile.login_streak || 0);
        console.log("Credits remaining set to:", data.profile.credits_remaining || 0);
      } else {
        console.log("No profile data found; defaulting login streak and credits remaining to 0.");
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };
  
  useEffect(() => {
    console.log("User ID:", userId);
    console.log("Token:", token);
    console.log("authToken:", authToken);
    if ((token || authToken)) { // Check if userId is present and either token or authToken is available
      fetchUserData();
    }
  }, [token, authToken]);

  useEffect(() => {
    if (userId) {
      localStorage.setItem('userId', userId);
      console.log("User ID saved to localStorage:", userId);
    }
    if (token) {
      localStorage.setItem('token', token);
      console.log("Token saved to localStorage:", token);
    }
    if (authToken) {
      console.log("authToken retrieved from cookies:", authToken);
    }
  }, [userId, token, authToken]);

  const value = {
    userId,
    setUserId,
    token,
    setToken,
    authToken,
    setAuthToken,
    loginStreak,
    creditsRemaining,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};



